import { cn } from '@/lib/utils';
import {
  CSSProperties,
  ChangeEventHandler,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  MouseEventHandler,
  forwardRef
} from 'react';

const TextField = forwardRef(
  (
    {
      outerContainerStyle,
      innerContainerStyle,
      outerContainerClassName,
      innerContainerClassName,
      fullWidth = false,
      disabled = false,
      pattern,
      required,
      endAdornment = null,
      autoComplete = 'off',
      placeholder,
      autoFocus = false,
      variant = 'standard',
      id,
      name,
      value,
      maxLength,
      error = false,
      helperText,
      helperTextStyle,
      onChange = null,
      onClick = null,
      onDoubleClick = null,
      onKeyDown = null,
      onFocus = null,
      onBlur = null,
      onMouseEnter = null,
      onMouseLeave = null,
      onMouseDown = null,
      label,
      multiline = false,
      rows = 2,
      type = 'text'
    }: {
      outerContainerStyle?: CSSProperties;
      innerContainerStyle?: CSSProperties;
      outerContainerClassName?: string;
      innerContainerClassName?: string;
      fullWidth?: boolean;
      disabled?: boolean;
      pattern?: string;
      required?: boolean;
      endAdornment?: React.ReactNode;
      autoComplete?: 'on' | 'off';
      placeholder?: string;
      autoFocus?: boolean;
      variant?: 'standard' | 'mui' | undefined;
      id?: string;
      name?: string;
      value?: string | number | string[];
      maxLength?: number;
      error?: boolean;
      helperText?: React.ReactNode;
      helperTextStyle?: CSSProperties;
      onChange?: ChangeEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      >;
      onClick?: MouseEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      >;
      onDoubleClick?: MouseEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      >;
      onKeyDown?: KeyboardEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      >;
      onFocus?: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      >;
      onBlur?: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      >;
      onMouseEnter?: MouseEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      >;
      onMouseLeave?: MouseEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      >;
      onMouseDown?: MouseEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      >;
      label?: string;
      multiline?: boolean;
      rows?: number;
      type?: HTMLInputTypeAttribute;
    },
    ref?:
      | React.ForwardedRef<HTMLInputElement>
      | React.LegacyRef<HTMLTextAreaElement>
  ) => (
    <div
      className={cn(
        'group/text-field-outer relative m-0 inline-flex min-w-0 flex-col border-0 border-none border-black p-0 align-top',
        fullWidth ? 'w-full' : '',
        outerContainerClassName,
        variant
      )}
      style={outerContainerStyle}
    >
      <div
        className={cn(
          'relative box-border inline-flex w-full items-center [-webkit-box-align:center]',
          disabled ? 'cursor-default' : 'cursor-text',
          disabled ? (variant == 'standard' ? '' : 'opacity-40') : '',
          innerContainerClassName
        )}
        style={{
          paddingTop: multiline ? rows * 2 : 0,
          paddingBottom: multiline ? 8 : 0,
          ...innerContainerStyle
        }}
      >
        {multiline ? (
          <textarea
            ref={ref as React.LegacyRef<HTMLTextAreaElement>}
            id={id}
            name={name}
            disabled={disabled}
            required={required}
            maxLength={maxLength}
            onChange={onChange}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={onMouseDown}
            aria-invalid="false"
            autoComplete={autoComplete}
            placeholder={placeholder}
            autoFocus={autoFocus}
            className={cn(
              'peer/text-field-input animate-auto-fill-cancel box-content block w-full resize-none appearance-none overflow-hidden border-0 border-none border-black bg-transparent bg-none bg-scroll bg-clip-border bg-origin-padding p-0 text-black [background-position-x:0%] [background-position-y:0%] [tab-size:4] focus:outline-0',
              disabled ? 'cursor-default' : 'cursor-text'
            )}
            style={{
              paddingTop: rows * 2
            }}
            rows={rows}
            value={value}
          />
        ) : (
          <input
            ref={ref as React.ForwardedRef<HTMLInputElement>}
            id={id}
            name={name}
            disabled={disabled}
            pattern={pattern}
            required={required}
            maxLength={maxLength}
            onChange={onChange}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={onMouseDown}
            aria-invalid="false"
            autoComplete={autoComplete}
            placeholder={placeholder}
            autoFocus={autoFocus}
            type={type}
            className={cn(
              'peer/text-field-input animate-auto-fill-cancel box-content block w-full border-0 border-none border-black bg-transparent bg-none bg-scroll bg-clip-border bg-origin-padding leading-6 text-black [background-position-x:0%] [background-position-y:0%] [tab-size:4] focus:outline-0',
              variant == 'standard'
                ? 'h-7 border-b border-dotted border-gray-400 py-1'
                : 'h-6 py-4 pl-3 pr-0',
              disabled ? 'cursor-default' : 'cursor-text'
            )}
            value={value}
          />
        )}
        {endAdornment}
        {variant != 'standard' ? (
          <fieldset
            aria-hidden="true"
            className={cn(
              'pointer-events-none absolute bottom-0 left-0 right-0 top-[-10px] box-border block min-w-0 overflow-hidden border border-solid px-2 py-0 text-left text-black [tab-size:4]',
              error ? 'border-red-600' : 'border-[#828282]',
              multiline
                ? 'rounded-3xl'
                : variant == 'mui'
                  ? 'rounded-md'
                  : 'rounded-[4rem]',
              !disabled
                ? 'cursor-text peer-focus/text-field-input:border-2'
                : 'cursor-default',
              error
                ? 'group-hover/text-field-outer:border-red-600 peer-focus/text-field-input:border-red-600'
                : 'group-hover/text-field-outer:border-blue-500 peer-focus/text-field-input:border-blue-500 peer-focus/text-field-input:text-blue-500'
            )}
          >
            <legend className="float-[unset] transition-[max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms] invisible block h-5 w-auto max-w-full overflow-hidden whitespace-nowrap p-0 text-xs">
              <span
                className={cn(
                  'notranslate visible inline-block',
                  error ? 'text-red-600' : '',
                  label ? 'px-1' : 'px-0 opacity-0'
                )}
              >
                {label}
              </span>
            </legend>
          </fieldset>
        ) : null}
      </div>
      {helperText ? (
        <p
          className={cn(
            'mx-4 mb-0 mt-1 text-sm',
            error ? 'text-red-600' : ''
          )}
          style={helperTextStyle}
        >
          {helperText}
        </p>
      ) : null}
    </div>
  )
);
export default TextField;
