import _ from 'lodash';
import { ReactNode } from 'react';
import { PageContextEnum } from 'src/config';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import useAllPages from '../useAllPages';

export type OptionTypeEnum = 'command' | 'dynamic-option' | 'nav-item' | 'link';

type OptionRecordType = {
  label: ReactNode;
  action: () => void | null;
  type: OptionTypeEnum;
  icon?: ReactNode;
  route?: string;
};
type OptionsType = { [key: string]: OptionRecordType };

export type ContextualOptionRecordType = {
  parent: PageContextEnum[];
  hasSearch: boolean;
  options: {
    [key in PageContextEnum]?: OptionRecordType;
  };
};
export type ContextualOptionsType = {
  [key in PageContextEnum]?: ContextualOptionRecordType;
};

function getOptionsFunc(
  contextualOptions: ContextualOptionsType,
  globalOptions: OptionsType
) {
  const useOptions = () => {
    const allPages = useAllPages();

    // create inverted index
    const invertedIndex = {};
    for (const key in contextualOptions) {
      if (contextualOptions[key].parent) {
        contextualOptions[key]?.parent.forEach((parent) => {
          if (!invertedIndex[parent]) {
            invertedIndex[parent] = {};
          }

          // build nav option
          const navOption = {
            key: key,
            label: allPages[key]?.label,
            action: allPages[key]?.action,
            icon: allPages[key]?.icon,
            type: 'nav-item'
          };

          invertedIndex[parent][key] = navOption;
        });
      }
    }

    // get all options for the current path from the inverted index
    for (const key in invertedIndex) {
      const options = invertedIndex[key];
      contextualOptions[key].options = {
        ...options,
        ...contextualOptions[key]?.options
      };
    }

    for (const key in contextualOptions) {
      //build dynamic nav option
      if (contextualOptions[key]) {
        const dynamicOption = {
          'Go-to': {
            key: 'Go-to',
            label: `Go to ${allPages[key]?.label}...`,
            action: allPages[key]?.action,
            icon: allPages[key]?.icon,
            type: 'dynamic-option'
          }
        };
        contextualOptions[key].options = {
          ...dynamicOption,
          ...contextualOptions[key].options
        };
      }
    }
    return { contextualOptions, globalOptions };
  };

  return useOptions;
}

export type BreadcrumbType = {
  key: string;
  label: string;
  open?: boolean;
  path?: string;
  parent?: string | null;
  action?: () => void;
};

export type SearchDialogStoreType = {
  globalOptions: OptionsType;
  setGlobalOptions: (newState: OptionsType, init: boolean) => void;
  contextualOptions: ContextualOptionsType;
  setContextualOptions: (newState: ContextualOptionsType) => void;
  //used for navigating search dialog page context
  contextualBreadcrumbs: BreadcrumbType[] | [];
  setContextualBreadcrumbs: (newState: BreadcrumbType[] | []) => void;
  addContextualBreadcrumbs: ({
    crumbs,
    after
  }: {
    crumbs: BreadcrumbType[];
    after: string;
  }) => void;
  popContextualBreadcrumb: () => void;

  getOptions: () => {
    contextualOptions: ContextualOptionsType;
    globalOptions: OptionsType;
  };
  setOptions: (
    contextualOptions: ContextualOptionsType,
    globalOptions: OptionsType
  ) => void;

  pushGlobalOptions: ({
    keyToPush,
    details
  }: {
    keyToPush: string;
    details: OptionRecordType;
  }) => void;
  popGlobalOptions: (keyToPop: string) => void;
  pushConditionalOptions: ({
    keyToPush,
    details,
    pageContext
  }: {
    keyToPush: string;
    details: OptionRecordType;
    pageContext: PageContextEnum;
  }) => void;
  popConditionalOptions: ({
    keyToPop,
    pageContext
  }: {
    keyToPop: string;
    pageContext: PageContextEnum;
  }) => void;

  addCommand: ({
    key,
    pageContext,
    label,
    action,
    icon
  }: {
    key: string;
    pageContext: PageContextEnum;
    label: ReactNode;
    action: () => void;
    icon?: ReactNode;
  }) => void;
  removeOption: ({
    key,
    pageContext
  }: {
    key: string;
    pageContext: PageContextEnum;
  }) => void;
  addDynamicOption: ({
    key,
    pageContext,
    label,
    action,
    icon
  }: {
    key: string;
    pageContext: PageContextEnum;
    label: ReactNode;
    action: () => void;
    icon?: ReactNode;
  }) => void;
  addShowAllPages: ({ pageContext }: { pageContext: PageContextEnum }) => void;

  //value: search value, page: page where search was initiated
  searchValue: string;
  setSearchValue: (newState: string) => void;
  searchValuePage: string;
  setSearchValuePage: (newState: string) => void;
  clear: () => void;
};

const clearState = () => useSearchDialogStore.persist.clearStorage();

const useSearchDialogStore = create<SearchDialogStoreType>()(
  persist(
    (set, get) => ({
      globalOptions: {},
      setGlobalOptions: (newState, init) => {
        set(
          init
            ? { globalOptions: newState }
            : {
                globalOptions: newState,
                // this getOptions recalculation feels redundant as we don't change/use globalOptions inside getOptionsFunc
                getOptions: getOptionsFunc(get().contextualOptions, newState)
              }
        );
      },
      contextualOptions: {},
      setContextualOptions: (newState) => {
        set({ contextualOptions: newState });
      },

      //adds breadcrumbs to the search dialog
      contextualBreadcrumbs: [],
      setContextualBreadcrumbs: (newState) => {
        set({ contextualBreadcrumbs: newState });
      },
      addContextualBreadcrumbs: ({ crumbs, after }) => {
        let currentContextualBreadcrumbs = [];
        const afterIndex = get().contextualBreadcrumbs.findIndex(
          (crumb) => crumb['key'] === after
        );
        if (afterIndex !== -1) {
          currentContextualBreadcrumbs = get().contextualBreadcrumbs.slice(
            0,
            afterIndex + 1
          );
        }
        set({
          contextualBreadcrumbs: [...currentContextualBreadcrumbs, ...crumbs]
        });
      },
      popContextualBreadcrumb: () => {
        return get().contextualBreadcrumbs.pop();
      },

      getOptions: () => {
        return getOptionsFunc(get().contextualOptions, get().globalOptions)();
      },
      setOptions: (contextualOptions, globalOptions) => {
        set({ getOptions: getOptionsFunc(contextualOptions, globalOptions) });
      },

      pushGlobalOptions: ({ keyToPush, details }) => {
        const currentGlobalOptions = get().globalOptions;
        if (currentGlobalOptions && !(keyToPush in currentGlobalOptions)) {
          set({
            globalOptions: { ...currentGlobalOptions, [keyToPush]: details }
          });
        }
      },
      popGlobalOptions: (keyToPop) => {
        const currentGlobalOptions = _.cloneDeep(get().globalOptions);
        if (currentGlobalOptions && keyToPop in currentGlobalOptions) {
          delete currentGlobalOptions[keyToPop];
          set({
            globalOptions: currentGlobalOptions
          });
        }
      },
      pushConditionalOptions: ({ keyToPush, details, pageContext }) => {
        const currentContextualOptions = _.cloneDeep(get().contextualOptions);
        if (currentContextualOptions[pageContext]) {
          if (
            !(keyToPush in currentContextualOptions[pageContext].options) ||
            keyToPush == 'Search-on'
          ) {
            //exception for search because the search value needs to update
            currentContextualOptions[pageContext].options[keyToPush] = details;
            set({
              contextualOptions: currentContextualOptions
            });
          }
        }
      },
      popConditionalOptions: ({ keyToPop, pageContext }) => {
        const currentContextualOptions = _.cloneDeep(get().contextualOptions);
        if (
          currentContextualOptions[pageContext] &&
          keyToPop in currentContextualOptions[pageContext].options
        ) {
          delete currentContextualOptions[pageContext].options[keyToPop];
          set({
            contextualOptions: currentContextualOptions
          });
        }
      },

      addCommand: ({
        key,
        pageContext,
        label,
        action,
        icon
      }: {
        key: string;
        pageContext: PageContextEnum;
        label: ReactNode;
        action: () => void;
        icon?: ReactNode;
      }) => {
        get().pushConditionalOptions({
          keyToPush: key,
          details: {
            label,
            action,
            icon,
            type: 'command'
          },
          pageContext
        });
      },
      removeOption: ({
        key,
        pageContext
      }: {
        key: string;
        pageContext: PageContextEnum;
      }) => {
        get().popConditionalOptions({
          keyToPop: key,
          pageContext: pageContext
        });
      },

      //options that show up at the top of the search dialog (above nav-items)
      addDynamicOption: ({
        key,
        pageContext,
        label,
        action,
        icon
      }: {
        key: string;
        pageContext: PageContextEnum;
        label: ReactNode;
        action: () => void;
        icon?: ReactNode;
      }) => {
        get().pushConditionalOptions({
          keyToPush: key,
          details: {
            label,
            action,
            icon,
            type: 'dynamic-option'
          },
          pageContext
        });
      },
      addShowAllPages: ({ pageContext }) => {
        get().pushConditionalOptions({
          keyToPush: 'show-all-pages',
          details: {
            label: 'Show all pages...',
            action: () => {
              get().setContextualBreadcrumbs([]);
            },
            type: 'link'
          },
          pageContext
        });

        // update contextualOptions upon adding a 'link' item
        get().setOptions(get().contextualOptions, get().globalOptions);
      },

      //value: search value, page: page where search was initiated
      searchValue: '',
      setSearchValue: (newState) => set({ searchValue: newState }),
      searchValuePage: '',
      setSearchValuePage: (newState) => set({ searchValuePage: newState }),
      clear: () => clearState()
    }),
    {
      name: 'search-store',
      partialize: (store: SearchDialogStoreType) => ({
        searchValue: store.searchValue,
        searchValuePage: store.searchValuePage
      })
    }
  )
);

export default useSearchDialogStore;
