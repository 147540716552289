import { gql, useQuery } from '@apollo/client';
import { useLayoutEffect } from 'react';
import { LayoutUserQueryQuery } from 'src/__apolloGenerated__/graphql';
import { INVENTORY_PAGE } from 'src/config';
import useAuthPersistStore, {
  AuthStoreType
} from 'src/hooks/store/useAuthPersistStore';
import useSearchDialogStore, {
  ContextualOptionsType,
  OptionTypeEnum,
  SearchDialogStoreType
} from 'src/hooks/store/useSearchDialogStore';
import useSubscriptionStore from 'src/hooks/store/useSubcriptionStore';
import useInitialBreadcrumbs from 'src/hooks/useInitialBreadcrumbs';

// ----------------------------------------------------------------------

function getInitialGlobalOptions(
  authState,
  authAction /*settingsDispatch*/
) {
  const globals = {
    // 'switch-theme': {
    //   label: 'Switch Theme',
    //   action: () => settingsDispatch('switch-mode'),
    //   icon: null,
    //   type: 'command' as OptionsTypeEnum
    // }
  };

  const authedOptions = authState.isAuthenticated
    ? {
        'sign-out': {
          label: 'Sign out',
          action: () => authAction(),
          icon: null,
          type: 'command' as OptionTypeEnum
        }
      }
    : {};

  return { ...authedOptions, ...globals };
}

// ----------------------------------------------------------------------

export default function Initializer({ children }) {
  const { authState, signout } = useAuthPersistStore(
    (store: AuthStoreType) => ({
      authState: store.authState,
      signout: store.signout
    })
  );
  const { activeSubscriptionTier, setActiveSubscriptionTier } =
    useSubscriptionStore();

  const initialBreadcrumbs = useInitialBreadcrumbs();

  //base for search dialog inverted index
  const initialContextualOptions: ContextualOptionsType =
    authState.isAuthenticated
      ? {
          dashboard: { parent: null, hasSearch: false, options: {} },
          inventory: {
            parent: ['dashboard'],
            hasSearch: false,
            options: {}
          },
          marketplace: {
            parent: ['dashboard'],
            hasSearch: true,
            options: {}
          },
          [INVENTORY_PAGE.MEASURE]: {
            parent: [
              'inventory',
              INVENTORY_PAGE.MEASURE,
              //INVENTORY_TAB.OFFSET,
              INVENTORY_PAGE.REDUCE,
              INVENTORY_PAGE.SHARE
            ],
            hasSearch: false,
            options: {}
          },
          [INVENTORY_PAGE.REDUCE]: {
            parent: [
              'inventory',
              INVENTORY_PAGE.MEASURE,
              INVENTORY_PAGE.REDUCE,
              // INVENTORY_TAB.OFFSET,
              INVENTORY_PAGE.SHARE
            ],
            hasSearch: false,
            options: {}
          },
          // [INVENTORY_TAB.OFFSET]: {
          //   parent: [
          //     'inventory',
          //     INVENTORY_TAB.MEASURE,
          //     INVENTORY_TAB.REDUCE,
          //     INVENTORY_TAB.OFFSET,
          //     INVENTORY_TAB.SHARE,
          //   ],
          //   hasSearch: false,
          //   options: {}
          // },
          [INVENTORY_PAGE.SHARE]: {
            parent: [
              'inventory',
              INVENTORY_PAGE.MEASURE,
              INVENTORY_PAGE.REDUCE,
              // INVENTORY_TAB.OFFSET,
              INVENTORY_PAGE.SHARE
            ],
            hasSearch: false,
            options: {}
          },

          // 'my-offsets': {
          //   parent: ['dashboard'],
          //   hasSearch: true,
          //   options: {}
          // },
          pricing: {
            parent: ['dashboard'],
            hasSearch: false,
            options: {}
          },
          settings: {
            parent: ['dashboard'],
            hasSearch: false,
            options: {}
          },
          playground: {
            parent: ['dashboard'],
            hasSearch: false,
            options: {}
          }
          // docs: {
          //   parent: ['dashboard'],
          //   hasSearch: true,
          //   options: {}
          // }
        }
      : {
          home: { parent: null, hasSearch: false, options: {} },
          signin: {
            parent: ['home'],
            hasSearch: false,
            options: {}
          },
          signup: {
            parent: ['home'],
            hasSearch: false,
            options: {}
          },
          marketplace: {
            parent: ['home'],
            hasSearch: true,
            options: {}
          },
          pricing: {
            parent: ['home'],
            hasSearch: false,
            options: {}
          },
          playground: {
            parent: ['home'],
            hasSearch: false,
            options: {}
          }
        };

  const initialGlobalOptions = getInitialGlobalOptions(
    authState,
    signout
    // settingsDispatch
  );

  // initialize SearchDialogStore
  const {
    setGlobalOptions,
    setContextualOptions,
    setContextualBreadcrumbs,
    // setBreadcrumbs,
    setOptions
  } = useSearchDialogStore((store: SearchDialogStoreType) => ({
    setGlobalOptions: store.setGlobalOptions,
    setContextualOptions: store.setContextualOptions,
    setContextualBreadcrumbs: store.setContextualBreadcrumbs,
    // setBreadcrumbs: store.setBreadcrumbs,
    setOptions: store.setOptions
  }));

  useLayoutEffect(() => {
    setGlobalOptions(initialGlobalOptions, true);
    setContextualOptions(initialContextualOptions);
    setContextualBreadcrumbs(initialBreadcrumbs);
    // setBreadcrumbs(initialBreadcrumbs);
    setOptions(initialContextualOptions, initialGlobalOptions);
  }, []);

  const { data } = useQuery<LayoutUserQueryQuery>(gql`
    query LayoutUserQuery {
      user {
        data {
          entity {
            billing {
              activePlan {
                tier
              }
            }
          }
        }
      }
    }
  `);
  const userData = data?.user?.data;

  // Initialize subscription tier
  useLayoutEffect(() => {
    if (
      userData?.entity?.billing?.activePlan?.tier !==
      activeSubscriptionTier
    ) {
      setActiveSubscriptionTier(
        userData?.entity?.billing?.activePlan?.tier
      );
    }
  }, [userData]);

  return (
    <div
      id="layout-container"
      style={{ height: '100%', width: '100%' }}
    >
      {children}
    </div>
  );
}
