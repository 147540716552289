import { useRouter } from 'next/router';
import { PAGE_LABELS } from 'src/config';
import useAuthPersistStore, {
  AuthStoreType
} from './store/useAuthPersistStore';
import { BreadcrumbType } from './store/useSearchDialogStore';

// ----------------------------------------------------------------------

export default function useInitialBreadcrumbs(): BreadcrumbType[] {
  const router = useRouter();
  const { authState }: AuthStoreType = useAuthPersistStore(
    (store: AuthStoreType) => ({
      authState: store.authState
    })
  );
  const pathname = router.pathname;
  const isHomepage = pathname == '/';
  let path = pathname.slice(1).split('/');

  //no need for breadcrumbs on auth pages
  if (path[0] === 'auth') {
    path = [];
  }

  const rootPage = authState.isAuthenticated ? 'dashboard' : 'home';

  //if page is a home page
  if (isHomepage) {
    path = [rootPage];
  } else {
    path = [rootPage, ...path];
  }

  let initialBreadcrumbs = [];
  if (path?.length > 0) {
    initialBreadcrumbs = path?.map((page) => {
      const pageIsHomepage = page == 'home';
      return {
        key: page,
        action: pageIsHomepage
          ? () => router.push('/')
          : () => router.push(`/${page}`),
        parent: path[path?.indexOf(page) - 1] || null,
        label: PAGE_LABELS[page]
      };
    });
  }
  return initialBreadcrumbs;
}
